import axios from '@/common/axios'
import qs from 'qs'

export function addHumidityRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/humidity/add',
    data: qs.stringify(data)
  })
}

export function deleteHumidityRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/humidity/delete/' + id
  })
}

export function updateHumidityRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/humidity/update',
    data: qs.stringify(data)
  })
}

export function selectHumidityRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/humidity/info/' + id
  })
}

export function selectHumidityRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/humidity/list',
    params: query
  })
}
