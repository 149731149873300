<template>
  <div id="humidityRecord">
    <el-dialog
      :title="humidityRecordFormTitle"
      width="680px"
      :visible.sync="humidityRecordDialogVisible"
      :close-on-click-modal="false"
      @close="humidityRecordDialogClose"
    >
      <el-form
        ref="humidityRecordFormRef"
        :model="humidityRecordForm"
        :rules="humidityRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="测定房间" prop="roomName">
              <el-input v-model="humidityRecordForm.roomName" placeholder="请输入测定房间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="房间等级" prop="roomLevel">
              <el-radio-group v-model="humidityRecordForm.roomLevel">
                <el-radio :label="1">
                  十万级
                </el-radio>
                <el-radio :label="2">
                  普通控制区
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="humidityRecordForm.deviceName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="humidityRecordForm.deviceNo" placeholder="请输入设备编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度要求" prop="temperatureClaim">
              <el-input v-model="humidityRecordForm.temperatureClaim" placeholder="请输入温度要求" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="湿度要求" prop="humidityClaim">
              <el-input v-model="humidityRecordForm.humidityClaim" placeholder="请输入湿度要求" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监测时间" prop="monitoringTime">
              <el-date-picker v-model="humidityRecordForm.monitoringTime" placeholder="请选择监测时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度" prop="temperature">
              <el-input v-model="humidityRecordForm.temperature" placeholder="请输入温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="湿度" prop="humidity">
              <el-input v-model="humidityRecordForm.humidity" placeholder="请输入湿度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="结论" prop="conclusion">
              <el-radio-group v-model="humidityRecordForm.conclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="2">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录人" prop="recorder">
              <el-input v-model="humidityRecordForm.recorder" placeholder="请输入记录人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="humidityRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="humidityRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="测定房间">
        <el-input v-model="searchForm.roomName" placeholder="请输入测定房间" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="humidityRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="roomName" label="测定房间" />
      <el-table-column label="房间等级">
        <template slot-scope="scope">
          <span v-if="scope.row.roomLevel === 1">十万级</span>
          <span v-if="scope.row.roomLevel === 2">普通控制区</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="temperatureClaim" label="温度要求" />
      <el-table-column prop="humidityClaim" label="湿度要求" />
      <el-table-column label="监测时间">
        <template slot-scope="scope">
          <span v-if="scope.row.monitoringTime">{{ scope.row.monitoringTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="temperature" label="温度" />
      <el-table-column prop="humidity" label="湿度" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 1">合格</span>
          <span v-if="scope.row.conclusion === 2">不合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="humidityRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addHumidityRecord,
  deleteHumidityRecord,
  updateHumidityRecord,
  selectHumidityRecordInfo,
  selectHumidityRecordList
} from '@/api/quality/humidityRecord'

export default {
  data () {
    return {
      humidityRecordDialogVisible: false,
      humidityRecordFormTitle: '',
      humidityRecordForm: {
        id: '',
        roomName: '',
        roomLevel: '',
        deviceName: '温、湿度计',
        deviceNo: '',
        temperatureClaim: '',
        humidityClaim: '',
        monitoringTime: '',
        temperature: '',
        humidity: '',
        conclusion: '',
        recorder: ''
      },
      humidityRecordFormRules: {
        roomName: [{ required: true, message: '测定房间不能为空', trigger: ['blur', 'change']}]
      },
      humidityRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        roomName: ''
      }
    }
  },
  created () {
    selectHumidityRecordList(this.searchForm).then(res => {
      this.humidityRecordPage = res
    })
  },
  methods: {
    humidityRecordDialogClose () {
      this.$refs.humidityRecordFormRef.resetFields()
    },
    humidityRecordFormSubmit () {
      if (this.humidityRecordFormTitle === '温、湿度记录表详情') {
        this.humidityRecordDialogVisible = false
        return
      }
      this.$refs.humidityRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.humidityRecordFormTitle === '新增温、湿度记录表') {
            this.humidityRecordForm.id = ''
            await addHumidityRecord(this.humidityRecordForm)
          } else if (this.humidityRecordFormTitle === '修改温、湿度记录表') {
            await updateHumidityRecord(this.humidityRecordForm)
          }
          this.humidityRecordPage = await selectHumidityRecordList(this.searchForm)
          this.humidityRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.humidityRecordFormTitle = '新增温、湿度记录表'
      this.humidityRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteHumidityRecord(row.id)
        if (this.humidityRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.humidityRecordPage = await selectHumidityRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.humidityRecordFormTitle = '修改温、湿度记录表'
      this.humidityRecordDialogVisible = true
      this.selectHumidityRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.humidityRecordFormTitle = '温、湿度记录表详情'
      this.humidityRecordDialogVisible = true
      this.selectHumidityRecordInfoById(row.id)
    },
    selectHumidityRecordInfoById (id) {
      selectHumidityRecordInfo(id).then(res => {
        this.humidityRecordForm.id = res.id
        this.humidityRecordForm.roomName = res.roomName
        this.humidityRecordForm.roomLevel = res.roomLevel
        this.humidityRecordForm.deviceName = res.deviceName
        this.humidityRecordForm.deviceNo = res.deviceNo
        this.humidityRecordForm.temperatureClaim = res.temperatureClaim
        this.humidityRecordForm.humidityClaim = res.humidityClaim
        this.humidityRecordForm.monitoringTime = res.monitoringTime
        this.humidityRecordForm.temperature = res.temperature
        this.humidityRecordForm.humidity = res.humidity
        this.humidityRecordForm.conclusion = res.conclusion
        this.humidityRecordForm.recorder = res.recorder
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    }
  }
}
</script>

<style>
</style>
